import {h} from 'preact'
import ValidationErrorList from './validation-error-list'

enum TextKey {
    consentLink = 'link_consent',
    consentLinkText = 'link_consent_text',
    textBefore = 'text_consent_before',
    textAfter = 'text_consent_after'
}

interface ConsentInformationProps {
    text: { [key: string]: string }
    ruleId: string
    consentCheckEnabled: boolean
    validationErrors?: string[]
    onConsentCheckChange?: (checked) => void
}

export default function ConsentInformation(props: ConsentInformationProps) {
    return props.text[TextKey.consentLink] ? <div class="evi-widget-consent">
        {props.consentCheckEnabled &&
            <input id={`evi-widget-consent-${props.ruleId}`}
                   onClick={(e) => props.onConsentCheckChange((e.target as HTMLInputElement).checked)}
                   class={`evi-widget-consent-required ${props.validationErrors && props.validationErrors.length ? 'evi-widget-input-error' : ''}`}
                   type="checkbox"/>}
        <label for={`evi-widget-consent-${props.ruleId}`} class={'evi-widget-consent-label'}>
            <span dangerouslySetInnerHTML={{__html: props.text[TextKey.textBefore]}}/>{' '}
            <a href={props.text[TextKey.consentLink]} target="_blank"
               dangerouslySetInnerHTML={{__html: props.text[TextKey.consentLinkText]}}/>{' '}
            <span dangerouslySetInnerHTML={{__html: props.text[TextKey.textAfter]}}/>{' '}
        </label>

        <ValidationErrorList text={props.text}
                             validationErrors={props.validationErrors}/>
    </div> : null
}
