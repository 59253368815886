import { Component, h } from "preact"
import Footer from '../components/footer'
import { BlacklistEntry, BlacklistStatus, IdentityFeatureType, Rule } from '../domain'
import Title from '../components/title'
import Description from '../components/description'
import { EviAPI } from '../evi-api'
import MinimizeControl from '../components/minimize-control'
import LeverSwitch from '../components/lever-switch'

interface BlacklistCockpitProps {
    rule: Rule
    email: string
    minimized: boolean
    onToggleMinimize: () => void
    eviAPI: EviAPI
}

interface BlacklistCockpitState {
    blacklistEntry: BlacklistEntry
}

export default class BlacklistCockpit extends Component<BlacklistCockpitProps, BlacklistCockpitState> {
    constructor (props) {
        super(props)
        this.setState({blacklistEntry: {}})
    }

    async componentDidMount () {
        const blacklistEntry = await this.getEmailBlacklistEntry()
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({blacklistEntry})
    }

    getEmailBlacklistEntry = async () => {
        const blacklistResponse = await this.props.eviAPI.getBlacklistEntries()
        return blacklistResponse.find(blacklistEntry => blacklistEntry.type === IdentityFeatureType.EMAIL)
    }

    isBlacklisted = () => {
        return this.state.blacklistEntry && this.state.blacklistEntry.status === BlacklistStatus.ACTIVE
    }

    onSubmit = async () => {
        this.isBlacklisted() ? await this.props.eviAPI.deleteBlacklistEntry() : await this.props.eviAPI.postBlacklistEntry()
        const blacklistEntry = await this.getEmailBlacklistEntry()
        this.setState({blacklistEntry})
    }

    render () {
        return <div id={`evi-widget-rule-${this.props.rule.id}`} class={`evi-widget evi-widget-type-blacklist-cockpit ${this.props.rule.cssClass || ''}`}>
            <MinimizeControl text={this.props.rule.data}
                             minimized={this.props.minimized}
                             onToggleMinimize={this.props.onToggleMinimize}/>
            <Title title={this.props.rule.data.title}/>
            {!this.props.minimized && <Description description={this.props.rule.data.text_block_email}/>}
            <LeverSwitch text={this.props.rule.data}
                         checked={this.isBlacklisted()}
                         value={this.props.email}
                         onSubmit={this.onSubmit}/>
            <Title title={this.props.email}/>
            {!!this.state.blacklistEntry && <span class="evi-widget-agent-info">{new Date(this.state.blacklistEntry.lastModified).toLocaleString()}, {this.state.blacklistEntry.info}</span>}
            {!this.props.minimized && <Footer text={this.props.rule.data}/>}
        </div>
    }
}
