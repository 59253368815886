import { Component, h } from 'preact'
import { Action, ActionPermissionType, DoubleOptInType } from '../domain'
import LeverSwitch from './lever-switch'
import { hasPendingPermissionLink } from '../functions/actions'
import Title from './title'
import AgentInfo from './agent-info'
import { lastModifiedInfo, permissionLinkInfo, permissionLinkStatus } from '../functions/agent'

interface ActionListProps {
    text: { [key: string]: string }
    doubleOptInType: DoubleOptInType
    actions: Action[]
    agent: string
    onSubmit: (e: any, actionId: string) => void
}

interface ActionListState {
    changedActions: string[]
}

export default class ActionList extends Component<ActionListProps, ActionListState> {
    constructor (props) {
        super(props)
        this.setState({changedActions: []})
    }

    onSubmit = (e, actionId) => {
        this.setState({changedActions: [...this.state.changedActions, actionId]})
        this.props.onSubmit(e, actionId)
    }

    showAgentInfo = (actionId: string) => {
        return !!this.props.agent && !this.state.changedActions.includes(actionId)
    }

    render () {
        const actions = this.props.actions.map(action => <li key={action.id}>
            <LeverSwitch text={this.props.text}
                         cssClasses={hasPendingPermissionLink(this.props.doubleOptInType, action, this.state.changedActions) ? 'evi-widget-switch-pending' : ''}
                         checked={action.status === ActionPermissionType.HAVE_PERMISSION}
                         value={action.id}
                         onSubmit={this.onSubmit}/>
            <Title title={action.name}/>
            {this.showAgentInfo(action.id) && <AgentInfo lastModified={lastModifiedInfo(action)}
                                                         origin={permissionLinkInfo(action, 'origin')}
                                                         granted={permissionLinkInfo(action, 'granted')}
                                                         revoked={permissionLinkInfo(action, 'revoked')}
                                                         confirmed={permissionLinkInfo(action, 'confirmed')}
                                                         expires={permissionLinkInfo(action, 'expires')}
                                                         lastModifiedBy={permissionLinkInfo(action, 'lastModifiedBy')}
                                                         status={permissionLinkStatus(action)}/>}
        </li>)

        return <ul class="evi-widget-switch-list">
            {actions}
        </ul>
    }
}
