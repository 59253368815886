import { Component, h } from "preact"
import { Rule } from '../domain'
import Title from '../components/title'
import Description from '../components/description'
import { EviAPI } from '../evi-api'

interface SignOffFromAllProps {
    emitter: EventTarget
    rule: Rule
    eviAPI: EviAPI
}

export default class SignOffFromAll extends Component<SignOffFromAllProps> {
    signOffFromAll = async (e) => {
        e.preventDefault()
        await this.props.eviAPI.deletePermissionLink({ruleIds: [this.props.rule.id]})
        this.props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: [this.props.rule] }}))
        this.props.emitter.dispatchEvent(new CustomEvent('widget-success'))
        window.location.reload()
    }

    render () {
        return <div id={`evi-widget-rule-${this.props.rule.id}`} class={`evi-widget evi-widget-type-sign-off-from-all ${this.props.rule.cssClass || ''}`}>
            <Title title={this.props.rule.data.title}/>
            <Description description={this.props.rule.data.text_above}/>
            <a onClick={(e) => this.signOffFromAll(e)} dangerouslySetInnerHTML={{__html: this.props.rule.data.link_text}}/>
        </div>
    }
}
