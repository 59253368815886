import {FunctionalComponent, h} from 'preact'
import ValidationErrorList from './validation-error-list'
import {Dispatch, StateUpdater} from 'preact/hooks'

enum TextKey {
    label = 'title_inputMail',
    title = 'text_helpinputfield',
    button = 'label_button'
}

export interface EmailSubmitProps {
    text: { [key: string]: string }
    ruleId: string
    email: string
    hideEmail?: boolean
    hasIdentity: boolean
    showLabel?: boolean
    validationErrors: string[]
    setEmail: Dispatch<StateUpdater<string>>
    onSubmit: (e: MouseEvent) => void
}

export const EmailSubmit: FunctionalComponent<EmailSubmitProps> = (props: EmailSubmitProps) =>  {

    function onEmailChange (email): void {
        props.setEmail(email)
    }

    function onEmailBlur (email: string) {
        props.setEmail(email.trim())
    }

    return <form>
        {props.showLabel &&
        props.text[TextKey.label] &&
        <label for={`evi-widget-email-${props.ruleId}`} dangerouslySetInnerHTML={{__html: props.text[TextKey.label]}}
               class={'evi-widget-label-email'}/>}
        <div class="evi-widget-email-input-container">
            <input id={`evi-widget-email-${props.ruleId}`}
                   class="evi-widget-email-input"
                   name="email"
                   value={props.email}
                   type="text"
                   alt={props.text[TextKey.title]}
                   placeholder={props.text[TextKey.title]}
                   title={props.text[TextKey.title]}
                   disabled={props.hasIdentity}
                   onChange={(e) => onEmailChange((e.target as HTMLInputElement).value)}
                   onBlur={(e) => onEmailBlur((e.target as HTMLInputElement).value)}/>
            {!props.hideEmail && props.text[TextKey.button] && <button class="evi-widget-submit" type="submit" onClick={(e) => props.onSubmit(e)}
                    title={props.text[TextKey.button]} dangerouslySetInnerHTML={{__html: props.text[TextKey.button]}}/>}
            <ValidationErrorList text={props.text}
                                 validationErrors={props.validationErrors}/>
        </div>
    </form>
}

export default EmailSubmit
