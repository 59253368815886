import {Action, ActionType, Category, PermissionLinkStatus} from '../domain'

export const constructCategories = (actions: Action[], categories: { id: string, name: string }[], revokeOnly = false): Category[] => {
    const categoryAction = actions.find(action => action.type === ActionType.CATEGORY)
    return (categories || []).map(category => {
        const foundCategoryPL = categoryAction.permissionLinks.find(categoryPL => categoryPL.value === category.id)
        return {
            ...category,
            status: foundCategoryPL ? foundCategoryPL.status : null,
            action: categoryAction
        }
    }).filter(c => !revokeOnly || c.status === PermissionLinkStatus.GRANTED)
}
