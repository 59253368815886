import { Action, PermissionLink } from '../domain'

export const toLocalDate = (value): string => {
    return new Date(value).toLocaleString()
}

export const lastModifiedInfo = (action: Action, filter: (permissionLink: PermissionLink) => boolean = () => true) => {
    return action && action.permissionLinks && action.permissionLinks.filter(filter).length ?
        toLocalDate(Math.max.apply(null, action.permissionLinks
            .filter(filter)
            .map(permissionLink => new Date(permissionLink.lastModified).getTime()))) : '-'
}

export const permissionLinkInfo = (action: Action, value, filter: (permissionLink: PermissionLink) => boolean = () => true) => {
    return (action && action.permissionLinks && action.permissionLinks.find(filter)) ? action.permissionLinks.find(filter)[value] : ''
}

export const permissionLinkStatus = (action: Action, filter: (permissionLink: PermissionLink) => boolean = () => true) => {
    return (action && action.permissionLinks && action.permissionLinks.find(filter)) ? action.permissionLinks.find(filter).status : ''
}
