import {Component, h} from 'preact'

enum TextKey {
    titleDoi = 'title_doi_sent',
    textDoi = 'text_doi_sent',
    titleSuccess = 'title_success',
    textSuccess = 'text_success'
}

interface GeneralSuccessProps {
    text: { [key: string]: string }
    isDoubleOptInEnabled: boolean
}

export default class GeneralSuccess extends Component<GeneralSuccessProps> {
    render() {
        return this.props.isDoubleOptInEnabled ?
            [
                <div key="title" class="evi-widget-title-doi-sent"
                     dangerouslySetInnerHTML={{__html: this.props.text[TextKey.titleDoi]}}/>,
                <div key="text" class="evi-widget-description-doi-sent"
                     dangerouslySetInnerHTML={{__html: this.props.text[TextKey.textDoi]}}/>
            ]
            :
            [
                <div key="title" class="evi-widget-title-success"
                     dangerouslySetInnerHTML={{__html: this.props.text[TextKey.titleSuccess]}}/>,
                <div key="text" class="evi-widget-description-success"
                     dangerouslySetInnerHTML={{__html: this.props.text[TextKey.textSuccess]}}/>
            ]
    }
}
