import {h} from 'preact'

interface BlacklistedProps {
    text: { [key: string]: string }
    blacklisted: boolean
}

export default function Blacklisted(props: BlacklistedProps) {
    return props.blacklisted ? <div class="evi-widget-blacklisted-error"
                                    dangerouslySetInnerHTML={{__html: props.text.text_identity_feature_blacklisted}}/> : null
}
