import {Component, h} from 'preact'
import {ActionPermissionType, Rule, Template, Token} from '../domain'
import {EviAPI} from '../evi-api'
import TicketAlarm2 from './TicketAlarm2'
import TicketAlarm from './TicketAlarm'
import CategoryNewsletterCockpit from './CategoryNewsletterCockpit'
import TicketAlarmCockpit from './TicketAlarmCockpit'
import GeneralNewsletterCockpit from './GeneralNewsletterCockpit'
import GeneralNewsletter from './GeneralNewsletter'
import SignOffFromAll from './SignOffFromAll'
import BlacklistCockpit from './BlacklistCockpit'

interface WidgetProps {
    emitter: EventTarget
    token: Token
    rule: Rule
    hasEmailIdentity: boolean
    email: string
    categories: {id: string, name: string}[]
    consentCheckEnabled: boolean
    error: () => void
    eviAPI: EviAPI
}

interface WidgetState {
    consentChecked: boolean
    minimized: boolean
}

export default class Widget extends Component<WidgetProps, WidgetState> {
    onConsentCheckChange = (checked) => {
        this.setState({consentChecked: checked})
    }

    onToggleMinimize = () => {
        this.setState({minimized: !this.state.minimized})
    }

    private tokenConsistencyChecked (): boolean|null {
        const hasAnyIdentityFeature = (this.props.token.identityFeatures?.length >= 1)
        if (!hasAnyIdentityFeature) {
            this.props.error?.()
        }
        return hasAnyIdentityFeature || null
    }

    render () {
        switch (this.props.rule.template) {
            case Template.CATEGORY_NEWSLETTER_COCKPIT: {
                return this.tokenConsistencyChecked() &&
                       <CategoryNewsletterCockpit emitter={this.props.emitter}
                                                  rule={this.props.rule}
                                                  agent={this.props.token.agent}
                                                  email={this.props.email}
                                                  categories={this.props.categories}
                                                  consentCheckEnabled={this.props.consentCheckEnabled}
                                                  consentChecked={this.state.consentChecked}
                                                  onConsentCheckChange={this.onConsentCheckChange}
                                                  minimized={this.state.minimized}
                                                  onToggleMinimize={this.onToggleMinimize}
                                                  eviAPI={this.props.eviAPI}/>}
            case Template.GENERAL_NEWSLETTER_COCKPIT:
            case Template.GENERAL_NEWSLETTER_COCKPIT_MULTI: {
                return this.tokenConsistencyChecked() &&
                       <GeneralNewsletterCockpit emitter={this.props.emitter}
                                                 rule={this.props.rule}
                                                 agent={this.props.token.agent}
                                                 email={this.props.email}
                                                 categories={this.props.categories}
                                                 consentCheckEnabled={this.props.consentCheckEnabled}
                                                 consentChecked={this.state.consentChecked}
                                                 onConsentCheckChange={this.onConsentCheckChange}
                                                 minimized={this.state.minimized}
                                                 onToggleMinimize={this.onToggleMinimize}
                                                 eviAPI={this.props.eviAPI}/>
            }
            case Template.TICKET_ALARM_COCKPIT: {
                return this.tokenConsistencyChecked() &&
                       <TicketAlarmCockpit emitter={this.props.emitter}
                                           rule={this.props.rule}
                                           agent={this.props.token.agent}
                                           email={this.props.email}
                                           consentCheckEnabled={this.props.consentCheckEnabled}
                                           consentChecked={this.state.consentChecked}
                                           onConsentCheckChange={this.onConsentCheckChange}
                                           minimized={this.state.minimized}
                                           onToggleMinimize={this.onToggleMinimize}
                                           eviAPI={this.props.eviAPI}/>
            }
            case Template.TICKET_ALARM: {
                return (this.props.rule.cssClass || '').includes('evi-widget-ticketalarm-2') ?
                    <TicketAlarm2 emitter={this.props.emitter}
                                  rule={this.props.rule}
                                  email={this.props.email}
                                  hasEmailIdentity={this.props.hasEmailIdentity}
                                  consentCheckEnabled={this.props.consentCheckEnabled}
                                  consentChecked={this.state.consentChecked}
                                  onConsentCheckChange={this.onConsentCheckChange}
                                  eviAPI={this.props.eviAPI}/> :
                    <TicketAlarm emitter={this.props.emitter}
                                 rule={this.props.rule}
                                 email={this.props.email}
                                 hasEmailIdentity={this.props.hasEmailIdentity}
                                 consentCheckEnabled={this.props.consentCheckEnabled}
                                 consentChecked={this.state.consentChecked}
                                 onConsentCheckChange={this.onConsentCheckChange}
                                 eviAPI={this.props.eviAPI}/>
            }
            case Template.GENERAL_NEWSLETTER:
            case Template.TICKET_ALARM_SINGLE: {
                return <GeneralNewsletter emitter={this.props.emitter}
                                          rule={this.props.rule}
                                          context={this.props.token.context}
                                          allGranted={this.props.rule.actions.every(a => a.status === ActionPermissionType.HAVE_PERMISSION)}
                                          email={this.props.email}
                                          consentCheckEnabled={this.props.consentCheckEnabled}
                                          consentChecked={this.state.consentChecked}
                                          hasIdentity={this.props.hasEmailIdentity}
                                          onConsentCheckChange={this.onConsentCheckChange}
                                          eviAPI={this.props.eviAPI}/>
            }
            case Template.SIGN_OFF_FROM_ALL: {
                return this.tokenConsistencyChecked() &&
                       <SignOffFromAll emitter={this.props.emitter}
                                       rule={this.props.rule}
                                       eviAPI={this.props.eviAPI}/>
            }
            case Template.BLACKLIST_COCKPIT: {
                if (this.props.token.agent) {
                    return <BlacklistCockpit rule={this.props.rule}
                                             email={this.props.email}
                                             minimized={this.state.minimized}
                                             onToggleMinimize={this.onToggleMinimize}
                                             eviAPI={this.props.eviAPI}/>
                }
            }
        }
    }
}
