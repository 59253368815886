import * as EmailValidator from 'email-validator'
import { flatMap } from './util'
import { ValidationError } from '../domain'

export const validateConsentCheck = (consentCheckEnabled, consentChecked): ValidationError[] => {
    return consentCheckEnabled && !consentChecked ? [ValidationError.ERROR_CONSENT_CHECK] : []
}

/**
 * ValidationError.ERROR_MISSINGDATA is sometimes used for emails and sometimes for missing artists
 * if it is used for missing artist, it should not be used for emails, Otherwise the validation text for email is wrong.
 * This is handled by the checkMissingData Parameter
 */
export const validateEmail = (email, checkMissingData?) => {
    if(!email && checkMissingData) {
        return [ValidationError.ERROR_MISSINGDATA]
    } else if (!EmailValidator.validate(email)) {
        return [ValidationError.ERROR_WRONGFORMATEMAIL]
    }
    return []
}

export const hasValidationErrors = (validationErrors: { [key: string]: ValidationError[] }) => {
    return !flatMap(Object.values(validationErrors)).length
}
