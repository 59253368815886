import {h} from 'preact'

interface TitleProps {
    title: string
}

export default function Title(props: TitleProps) {
    return props.title ?
        <div class="evi-widget-title"><span dangerouslySetInnerHTML={{__html: props.title}}/></div> : null
}
