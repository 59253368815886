import { useEffect } from 'preact/hooks'
import { ticketAlarmOnSelectedAutocomplete } from '../functions/ticketalarm'
import { isActionDoubleOptIn } from '../functions/actions'

export function useAddArtist(emitter: EventTarget, artists, doubleOptInType, action, setAnimateId, setArtists, setLastRemoved): void {
    useEffect(() => {
        const handler = (event: Event) => {
            const artistToAdd = (event as CustomEvent).detail
            ticketAlarmOnSelectedAutocomplete(artists, {id: artistToAdd.artistId, name: artistToAdd.name},
                isActionDoubleOptIn(doubleOptInType, action), setAnimateId, setArtists, setLastRemoved)
        }
        emitter.addEventListener('addArtist', handler)
        return () => emitter.removeEventListener('addArtist', handler)
    }, [emitter, artists, doubleOptInType, action, setAnimateId, setArtists, setLastRemoved])
}
