import {h} from 'preact'

enum TextKey {
    footerText = 'text_below'
}

interface FooterProps {
    /**
     * key/value list of texts. Only the key 'text_below' will be used
     */
    text: { [key: string]: string }
}

export default function Footer(props: FooterProps) {
    return props.text && props.text[TextKey.footerText]
        ? <div class="evi-widget-footer" dangerouslySetInnerHTML={{__html: props.text[TextKey.footerText]}}/>
        : null
}
