import {h} from 'preact'

interface DescriptionProps {
    description: string
}

export default function Description(props: DescriptionProps) {
    return props.description ?
        <div class="evi-widget-description" dangerouslySetInnerHTML={{__html: props.description}}/> : null
}
