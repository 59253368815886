import {Component, ComponentChild, h} from 'preact'
import { toLocalDate } from '../functions/agent'
import { PermissionLinkStatus } from '../domain'

interface AgentInfoProps {
    lastModified: string
    origin: string
    granted: string
    revoked: string
    confirmed: string
    expires: string
    lastModifiedBy: string
    status: string
}

export default class AgentInfo extends Component<AgentInfoProps> {
    private isConfirmedStatus = (): boolean => {
        return this.props.status === PermissionLinkStatus.GRANTED
    }

    private isRevokedStatus = (): boolean => {
        return this.props.revoked && (this.props.revoked > this.props.confirmed || isNaN(new Date(this.props.confirmed).getTime()))
    }

    private currentStatus = (): string => {
        if (this.isConfirmedStatus()) {
            return `last subscription date: ${  toLocalDate(this.props.confirmed)}`
        } else if(this.isRevokedStatus()) {
            return `revoked/consent denied: ${  toLocalDate(this.props.revoked)}`
        }
        return '-'
    }

    private lastStatus = (): string => {
        if(this.props.confirmed && this.props.revoked) { // there was already a state transition
            return this.props.confirmed < this.props.revoked ?  `last subscription date: ${  toLocalDate(this.props.confirmed)}` : `revoked/consent denied: ${  toLocalDate(this.props.revoked)}`
        }
        return '-'
    }

    private expires = (): string => {
        if (this.props.expires && this.props.status === PermissionLinkStatus.GRANTED) {
            return toLocalDate(this.props.expires)
        }
        return '-'
    }

    render (): ComponentChild {
        return <table class="evi-widget-agent-info">
            <thead/>
            <tbody>
                <tr>
                    <td><span class="evi-widget-agent-info-title">last update</span><br/>{this.props.lastModified}</td>
                    <td><span class="evi-widget-agent-info-title">origin</span><br/>{this.props.origin ? this.props.origin : '-'}</td>
                    <td><span class="evi-widget-agent-info-title">current status</span><br/>{this.currentStatus()}</td>
                    <td><span class="evi-widget-agent-info-title">expires</span><br/>{this.expires()}</td>
                    <td><span class="evi-widget-agent-info-title">user</span><br/>{this.props.lastModifiedBy ? this.props.lastModifiedBy : '-'}</td>
                    {this.props.status === PermissionLinkStatus.PENDING ? <td><span class="evi-widget-agent-info-title">double opt in</span><br/>PENDING</td> : ''}
                </tr>
                <tr>
                    <td><span class="evi-widget-agent-info-title">previous status</span><br/>{this.lastStatus()}</td>
                    <td colSpan={5}><span class="evi-widget-agent-info-title">initial subscription</span><br/>{this.props.granted ? toLocalDate(this.props.granted) : '-'}</td>
                </tr>
            </tbody>
        </table>
    }
}
