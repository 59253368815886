import {h} from 'preact'

interface MinimizeControlProps {
    text: { [key: string]: string }
    minimized: boolean
    onToggleMinimize: () => void
}

export default function MinimizeControl(props: MinimizeControlProps) {
    return <label class="evi-widget-toggle" onClick={props.onToggleMinimize}>
        <input title="toggle" type="checkbox" checked={props.minimized} onClick={props.onToggleMinimize}/>
        <span className="evi-widget-toggle-max">{props.text.maximize}</span>
        <span className="evi-widget-toggle-min">{props.text.minimize}</span>
    </label>
}
