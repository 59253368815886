import {h} from 'preact'
import ResendPermissionLink from './resend-permission-link'
import { EviAPI } from '../evi-api'
import AllPermissionsGranted from './all-permissions-granted'

interface DoubleOptInPendingProps {
    text: { [key: string]: string }
    ruleId: string
    eviAPI: EviAPI
}

export default function DoubleOptInPending(props: DoubleOptInPendingProps) {
    // GCPS-1079: This feature requires additional text resources in order to display properly.
    // For backward compatibility, a lack of those text resources effectively MUST disable the whole feature. 
    // The failover component "AllPermissionsGranted" was chosen deliberately w.r.t. backward compatibility, too!
    const haveResourcesForDisplayingPendingConfirmInformationAndResendLink = !!props.text.text_doi_pending
    if (!haveResourcesForDisplayingPendingConfirmInformationAndResendLink) {
        return <AllPermissionsGranted text={props.text}/>
    }

    return <div class="evi-widget-doi-pending">
        <div class="evi-widget-title-doi-pending" dangerouslySetInnerHTML={{__html: props.text.title_doi_pending}}/>
        <div class="evi-widget-description-doi-pending"
             dangerouslySetInnerHTML={{__html: props.text.text_doi_pending}}/>
        <ResendPermissionLink text={props.text}
                              hasEmailIdentity={true}
                              ruleId={props.ruleId}
                              hasDoubleOptInContent={true}
                              eviAPI={props.eviAPI}/>
    </div>
}
