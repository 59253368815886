export enum ActionPermissionType {
    WANT_PERMISSION = "WANT_PERMISSION",
    HAVE_PERMISSION = "HAVE_PERMISSION"
}

export enum DoubleOptInType {
    NONE = 'NONE',
    IDENTITY = 'IDENTITY',
    PERMISSION_LINK = 'PERMISSION_LINK'
}

export enum OptInType {
    OPT_IN = 'OPT_IN',
    OPT_OUT = 'OPT_OUT',
    REVERSE_OPT_IN = 'REVERSE_OPT_IN',
    REVERSE_OPT_OUT = 'REVERSE_OPT_OUT'
}

export enum PreselectionType {
    NOT_PRESELECTED = 'NOT_PRESELECTED',
    FALLBACK_REVOKED = 'FALLBACK_REVOKED',
    CONFIRMED = 'CONFIRMED',
    REVOKED = 'REVOKED',
    BACKWARDS_COMPATIBLE = 'BACKWARDS_COMPATIBLE'
}

export enum Template {
    GENERAL_NEWSLETTER = 'GENERAL_NEWSLETTER',
    GENERAL_NEWSLETTER_COCKPIT = 'GENERAL_NEWSLETTER_COCKPIT',
    GENERAL_NEWSLETTER_COCKPIT_MULTI = 'GENERAL_NEWSLETTER_COCKPIT_MULTI',
    TICKET_ALARM = 'TICKET_ALARM',
    TICKET_ALARM_SINGLE = 'TICKET_ALARM_SINGLE',
    TICKET_ALARM_COCKPIT = 'TICKET_ALARM_COCKPIT',
    CATEGORY_NEWSLETTER_COCKPIT = 'CATEGORY_NEWSLETTER_COCKPIT',
    SIGN_OFF_FROM_ALL = 'SIGN_OFF_FROM_ALL',
    CHECKBOX = 'CHECKBOX',
    RADIO_BUTTON = 'RADIO_BUTTON',
    RADIO_BUTTON_ONCE = 'RADIO_BUTTON_ONCE',
    BLACKLIST_COCKPIT = 'BLACKLIST_COCKPIT',
}

export enum ValidationError {
    ERROR_CONSENT_CHECK = 'error_consent_check',
    ERROR_MISSINGDATA = 'error_missingdata',
    ERROR_WRONGFORMATEMAIL = 'error_wrongformateMail'
}

/**
 * Main options for initializing evi client
 */
export interface WidgetOptions {
    /**
     * EVI backend base url
     */
    baseURL: string

    /**
     * Artist backend base url
     */
    artistBaseURL?: string

    /**
     * The EVI token
     */
    token: string

    /**
     * Callback, that is invoked when an error occurred
     */
    error: () => void

    /**
     * Map of categories. Only relevant for the category widget
     */
    categories?: {id: string, name: string}[]

    /**
     * Query selector matching the input element for the email address.
     */
    mailInputSelector?: string
}

export interface RuleResponse {
    features: any
    revision: string
    rules: Rule[]
}

export interface SubscriptionEventPayload {
    rules: StrippedRule[]
}

export interface BaseRule {
    id: string
    name: string
    doubleOptInType: DoubleOptInType
    optInType: OptInType
    preselectionType?: PreselectionType
    template: Template
    version: number
    cssClass: string
}

export interface Rule extends BaseRule {
    actions: Action[]
    data: { [key: string]: string }
    status: ActionPermissionType
}

export interface StrippedRule extends BaseRule {
    actions: BaseAction[]
}

export interface BaseAction {
    id: string
    version: number,
    type: ActionType
    doubleOptIn: boolean
}

export interface Action extends BaseAction {
    status: ActionPermissionType
    permissionLinks: PermissionLink[]
    data: { [key: string]: string }
    name?: string
    permissionLinkStatus?: PermissionLinkStatus
}

export enum ActionType {
    GENERAL = 'GENERAL',
    ARTIST = 'ARTIST',
    EVENT = 'EVENT',
    CATEGORY = 'CATEGORY'
}

export interface PermissionLink {
    rule: Rule
    status: PermissionLinkStatus
    value: string
    revision: string
    granted: string
    revoked: string
    confirmed: string
    lastModifiedBy: string
    lastModified: string
    importData: {[key: string]: string}
    origin: string
}

export interface PermissionLinkProperties {
    ruleIds: string[]
    actionId?: string
    context?: any
}

export enum PermissionLinkStatus {
    PENDING = 'PENDING',
    GRANTED = 'GRANTED',
    REVOKED = 'REVOKED'
}

export enum BlacklistStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export interface BlacklistEntry {
    created?: string
    hitCount?: number
    creationDate?: string
    info?: string
    lastModified?: string
    status?: BlacklistStatus
    tenant?: Tenant[]
    type?: IdentityFeatureType
    value?: string
}

export interface ArtistResponse {
    active: boolean
    artistId: string
    creationDate: string
    id: string
    modificationDate: string
    name: string
    platformId: string
    score: any
    systemId: string
    thumbnailUri: string
}

export interface Artist {
    id: string,
    name: string,
    action?: Action
    status?: string,
    pending?: boolean,
    thumbnailUri?: string
}

export interface Context {
    [key: string]: any
}

export interface Token {
    agent: string
    context: Context,
    creationDate: string
    identityFeatures: IdentityFeature[]
    tenant: Tenant
    version: string
}

export interface Tenant {
    systemId: string,
    platformId: string,
    customerPool: string
    locale?: string[]
    doubleOptInType?: DoubleOptInType,
    replyToAddress?: string
    fromAddress?: string
    data?: { [key: string]: string }
    landingPageUrl?: string
    blacklistIds?: { [key in IdentityFeatureType]: string }
}

export interface IdentityFeature {
    type: IdentityFeatureType
    value: string
}

export enum IdentityFeatureType {
    EMAIL = 'EMAIL',
    WEB_ACCOUNT_ID = 'WEB_ACCOUNT_ID',
}

export interface Category {
    id: string
    name: string
    status: PermissionLinkStatus
    action: Action
}

export interface Capture {
    rules: {create: string[], revoke: string[]},
    options: WidgetOptions,
    token: Token
    identityFeatureValue: string,
    revision: string,
}
