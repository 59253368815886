import {h} from 'preact'

interface ValidationErrorListProps {
    /**
     * Mapping of validation error keys to printable text.
     * If an error is not present here, the error will be omitted
     */
    text:  { [key: string]: string }
    /**
     * List of validation error keys
     */
    validationErrors: string[]
}

export default function ValidationErrorList(props: ValidationErrorListProps) {
    return props.validationErrors && props.validationErrors.length ?
        <ul class="evi-widget-form-error">
            {props.validationErrors
                .map(value => props.text[value])
                .filter(value => !!value)
                .map(value => <li key={value}>{value}</li>)}
        </ul> : null
}
