import {Component, h} from 'preact'
import {EviAPI} from '../evi-api'

interface ResendPermissionLinkProps {
    text: { [key: string]: string }
    ruleId: string
    hasEmailIdentity: boolean
    hasDoubleOptInContent: boolean
    eviAPI: EviAPI
}

interface ResendPermissionLinkState {
    permissionLinkResent: boolean
}

export default class ResendPermissionLink extends Component<ResendPermissionLinkProps, ResendPermissionLinkState> {

    constructor(props) {
        super(props)
        this.setState({permissionLinkResent: false})
    }

    onResendPermissionLink = async (e) => {
        e.preventDefault()
        await this.props.eviAPI.postResendPermissionLink([this.props.ruleId])
        this.setState({permissionLinkResent: true})
    }

    render() {
        if (!this.props.text.link_resend_permission_link || !this.props.hasEmailIdentity || !this.props.hasDoubleOptInContent) {
            return null
        }
        if (this.state.permissionLinkResent) {
            return <div class="evi-widget-resend-permission-link-success" dangerouslySetInnerHTML={{__html: this.props.text.text_resend_permission_link_success}}/>

        }
        return <div>
            <div class="evi-widget-resend-permission-link">
                <span dangerouslySetInnerHTML={{__html: this.props.text.text_resend_permission_link_before}}/>{' '}
                <a href="#" onClick={(e) => this.onResendPermissionLink(e)} dangerouslySetInnerHTML={{__html: this.props.text.link_resend_permission_link}}/>{' '}
                <span dangerouslySetInnerHTML={{__html: this.props.text.text_resend_permission_link_after}}/>{' '}
            </div>
        </div>

    }
}
