import { ArtistResponse, BlacklistEntry, PermissionLinkProperties, Token, WidgetOptions } from './domain'

declare const VERSION: string  // from webpack.DefinePlugin

/**
 * EVI REST API
 * Error handling is done here. API will respond with undefined if an error has occurred.
 */
export interface EviAPI {
    /**
     * Updates the revision that has been set during init
     * @param rev the revision
     */
    updateRevision(rev: string): void

    /**
     * Loads the Rule matching the given token
     * @param token the token
     */
    getRule: (token: string) => Promise<any>
    postPermissionLink: (params: { ruleIds: string[], context?: any, email?: string, actionId?: string }, timeout?: number) => Promise<{ features: any }>
    deletePermissionLink: (params: { ruleIds: string[], context?: any, email?: string, actionId?: string }, timeout?: number) => Promise<void>
    postResendPermissionLink: (ruleIds: string[], timeout?: number) => Promise<{ features: any }>
    postPermissionLinkChangeRequest: (params: {email?: string, create: PermissionLinkProperties[], revoke: PermissionLinkProperties[]}, timeout?) => Promise<{ features: any }>
    getBlacklistEntries: () => Promise<BlacklistEntry[]>
    postBlacklistEntry: (timeout?: number) => Promise<void>
    deleteBlacklistEntry: (timeout?: number) => Promise<void>
    getArtistsById: (artistIds: string[]) => Promise<ArtistResponse[]>
    getArtistSuggestions: (query: string) => Promise<ArtistResponse[]>
}

const fetchWithTimeout = (url, options, timeout = 60000): Promise<Response> => {
    return Promise.race([
        fetch(url, options),
        new Promise<Response>((_, reject) =>
            setTimeout(() => reject(new Error('timeout')), timeout)
        )
    ])
}

export const api = (options: WidgetOptions, token: Token, revision: string, email: string): EviAPI => {
    const headers = {"Content-Type": "application/json"}
    const baseURL = options.baseURL.replace(/\/+$/, '')
    const artistBaseURL = (options.artistBaseURL || (`${options.baseURL  }/../../../artist/api`)).replace(/\/+$/, '')
    const fetchAndHandleError = async (url, params?, timeout?): Promise<any | undefined> => {
        let error
        try {
            const response = await fetchWithTimeout(url, params, timeout)
            if (response.status == 204) {
                return null
            } else if (response.ok) {
                return await response.json()
            }
            error = Error(`${response.status} - ${response.statusText}`)
        } catch (e) {
            error = e
        }
        if (error) {
            options.error?.()
            console.error(error)
        }
    }
    let currentRevision = revision
    return {
        updateRevision(rev) { currentRevision = rev },
        getRule(token: string) {
            return fetchAndHandleError(`${baseURL}/public/rule?evi-token=${token}&widget-version=${VERSION}`)
        },
        postPermissionLink: async (params: { ruleIds: string[], context?: any, email?: string, actionId?: string }, timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/permission-link`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    eviConfigToken: options.token,
                    widgetVersion: VERSION,
                    revision: currentRevision,
                    context: params.context,
                    agent: token.agent,
                    identityFeatureValue: params.email || email,
                    ruleIds: params.ruleIds,
                    actionId: params.actionId
                })
            }, timeout)
        },
        deletePermissionLink: async (params: { ruleIds: string[], context?: any, email?: string, actionId?: string }, timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/permission-link`, {
                method: 'DELETE',
                headers,
                body: JSON.stringify({
                    eviConfigToken: options.token,
                    identityFeatureValue: params.email || email,
                    widgetVersion: VERSION,
                    context: params.context,
                    agent: token.agent,
                    revision: currentRevision,
                    ruleIds: params.ruleIds,
                    actionId: params.actionId
                })
            }, timeout)
        },
        postResendPermissionLink: async (ruleIds: string[], timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/permission-link/resend`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    eviConfigToken: options.token,
                    revision: currentRevision,
                    ruleIds,
                    widgetVersion: VERSION
                })
            }, timeout)
        },
        postPermissionLinkChangeRequest: async(params: {email?: string, create: PermissionLinkProperties[], revoke: PermissionLinkProperties[]}, timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/permission-link/change`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    eviConfigToken: options.token,
                    revision: currentRevision,
                    identityFeatureValue: params.email || email,
                    create: params.create,
                    revoke: params.revoke,
                    widgetVersion: VERSION
                })
            }, timeout)
        },
        getBlacklistEntries: async () => {
            return fetchAndHandleError(`${baseURL}/public/blacklist?evi-token=${options.token}&widget-version=${VERSION}`)
        },
        postBlacklistEntry: async (timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/blacklist/EMAIL`, {
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                method: 'POST',
                body: `evi-token=${options.token}&widget-version=${VERSION}`
            }, timeout)
        },
        deleteBlacklistEntry: async (timeout?) => {
            return fetchAndHandleError(`${baseURL}/public/blacklist/EMAIL?evi-token=${options.token}&widget-version=${VERSION}`, {
                method: 'DELETE'
            }, timeout)
        },
        getArtistSuggestions: async (query) => {
            return fetchAndHandleError(`${artistBaseURL}/artists/suggest?q=${encodeURIComponent(query)}&systemId=${token.tenant.systemId.toUpperCase()}&platformId=${token.tenant.platformId.toUpperCase()}`)
        },
        getArtistsById: async (artistIds) => {
            const requests = artistIds
                .reduce((result, currentValue, currentIndex) => {
                    if (currentIndex % 100 == 0) {
                        result.push([])
                    }
                    result[result.length - 1].push(currentValue)
                    return result
                }, [])
                .map(ids => ids.join(','))
                .map(ids => `${artistBaseURL}/artists?artistIds=${ids}&systemId=${token.tenant.systemId.toUpperCase()}&platformId=${token.tenant.platformId.toUpperCase()}`)
                .map(url => fetchAndHandleError(url))
            return Promise.all(requests).then(responses => [].concat(...responses))
        }
    }
}
