import {Component, h} from 'preact'

enum TextKey {
    errorTitle = 'title_error',
    errorDescription = 'text_identity_feature_blacklisted',
    backLink = 'text_error_back'
}

interface GeneralErrorProps {
    text: { [key: string]: string }
    onBackLink?: () => void
}

export default class GeneralError extends Component<GeneralErrorProps> {
    render() {
        let title
        let description
        let resetLink
        if (this.hasText(TextKey.errorTitle)) {
            title = <div class="evi-widget-title-error" dangerouslySetInnerHTML={{__html: this.props.text[TextKey.errorTitle]}}/>
        }
        if (this.hasText(TextKey.errorDescription)) {
            description = <div class="evi-widget-description-error" dangerouslySetInnerHTML={{__html: this.props.text[TextKey.errorDescription]}}/>
        }
        if (this.props.onBackLink && this.hasText(TextKey.backLink)) {
            resetLink = <div class="evi-widget-reset" onClick={this.props.onBackLink} dangerouslySetInnerHTML={{__html: this.props.text[TextKey.backLink]}}/>
        }
        if (title || description || resetLink) {
            return <div class="evi-widget-error">
                {title}
                {description}
                {resetLink}
            </div>
        }
        return null
    }

    hasText(key: TextKey) {
        return this.props.text && this.props.text[key]
    }
}
