import {h} from 'preact'

interface AllPermissionsGrantedProps {
    text: { [key: string]: string }
}

export default function AllPermissionsGranted(props: AllPermissionsGrantedProps) {
    return <div class="evi-widget-permitted">
        <div class="evi-widget-title-permitted" dangerouslySetInnerHTML={{__html: props.text.title_alreadyregistered}}/>
        <div class="evi-widget-description-permitted"
             dangerouslySetInnerHTML={{__html: props.text.text_alreadyregistered}}/>
    </div>
}
