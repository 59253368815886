import { flatMap } from './util'
import { ActionType, Artist, ArtistResponse, PermissionLinkStatus, Rule } from '../domain'
import { EviAPI } from '../evi-api'

export const compareArtists = (a: Artist, b: Artist): number => {
    const nameA = a.name ? a.name.toUpperCase() : ''
    const nameB = b.name ? b.name.toUpperCase() : ''
    return nameA.localeCompare(nameB)
}

const mapArtistResponse = (artistResponse: ArtistResponse[], rule): Artist[] => {
    const pendingArtists = flatMap(rule.actions
        .filter(action => action.type === ActionType.ARTIST)
        .filter(action => action.permissionLinks)
        .map(action => action.permissionLinks
            .filter(permissionLink => permissionLink.status === PermissionLinkStatus.PENDING)
            .map(permissionLink => permissionLink.value)))
    return artistResponse.map(artist => ({
        ...artist,
        id: artist.artistId,
        action: rule.actions.find(action => action.type === ActionType.ARTIST),
        name: artist.name.trim(),
        pending: pendingArtists.includes(artist.artistId)
    })).sort(compareArtists)
}

export const loadSubscribedArtists = async (eviAPI: EviAPI, rule: Rule): Promise<Artist[]> => {
    const existingArtistIds = flatMap(rule.actions
        .filter(action => action.type === ActionType.ARTIST && action.permissionLinks)
        .map(action => action.permissionLinks
            .filter(permissionLink => permissionLink.status !== PermissionLinkStatus.REVOKED)
            .map(permissionLink => permissionLink.value)))
    const artistsResponse: ArtistResponse[] = await eviAPI.getArtistsById(existingArtistIds)
    return mapArtistResponse(artistsResponse, rule)
}
