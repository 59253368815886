import {h} from 'preact'

interface LeverSwitchProps {
    text: { [key: string]: string }
    checked: boolean
    value: string
    cssClasses?: string
    onSubmit: (e: any, value: string) => void
}

export default function LeverSwitch(props: LeverSwitchProps) {
    return <label className={`evi-widget-switch ${props.cssClasses}`}>
        <input className="evi-widget-checkbox" type="checkbox"
               onClick={(e) => props.onSubmit(e, props.value)}
               value={props.value}
               checked={props.checked}/>
        <span className="evi-widget-switch-lever">
                <span className="evi-widget-switch-left" dangerouslySetInnerHTML={{__html: props.text.yes}}/>
                <span className="evi-widget-switch-center">&nbsp;</span>
                <span className="evi-widget-switch-right" dangerouslySetInnerHTML={{__html: props.text.no}}/>
            </span>
    </label>
}
